body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Proxima Nova A';
  src: url('./assets/ProximaNovaA-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.d-flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-baseline {
  align-items: baseline;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-flex-end {
  justify-content: end;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.gap-24 {
  gap: 24px;
}

.gap-28 {
  gap: 28px;
}

.gap-32 {
  gap: 32px;
}

.table-error-color {
  background-color: #fef2f2 !important;
}

.table-error-color:hover {
  filter: brightness(1.02);
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-wrap-no-wrap {
  text-wrap: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.hyperlink-text {
  color: #3b82f6;
  text-decoration: underline;
  cursor: pointer;
}

.fit-content {
  width: fit-content;
}

/*
Scroll css
*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f7f7f7;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9d9d9d;
}
